import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import PasswordField from '../../../shared/components/form/Password';
import ResetPassword from './ResetPassword';


const LogInForm = ({ onSubmit }) => {
    const [errorMsg,setError] = useState(false);
    const [viewResetPassword,setViewResetPassword] = useState(false);

    const toogleResetPassword = ()=>{
      setViewResetPassword(true);
    };
    const toggle=() => {
        setViewResetPassword(prevState=>!prevState);
    };

    const handleCloseModal = ( event, data)=> {
        console.log(event, data);
        setViewResetPassword(false);
    };

  return(
      <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
              <form className="form" onSubmit={handleSubmit}>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Username</span>
                      <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                              <AccountOutlineIcon />
                          </div>
                          <Field
                              name="username"
                              component="input"
                              type="text"
                              placeholder="username"
                          />
                      </div>
                  </div>
                  <div className="form__form-group">
                      <span className="form__form-group-label">Password</span>
                      <div className="form__form-group-field">
                          <Field
                              name="password"
                              component={PasswordField}
                              placeholder="Password"
                              className="input-without-border-radius"
                              keyIcon
                          />
                      </div>
                  </div>
                  <div className="form__form-group">
                      <div className="form__form-group-field">
                          <Field
                              name="remember_me"
                              component={renderCheckBoxField}
                              label="Remember me"
                              type="checkbox"
                          />
                      </div>
                  </div>
                  <a className="bold-text pb-3" href="javascript:void()" onClick={toogleResetPassword}>Forgot your Password?</a>
                  <ResetPassword toggle={toggle} isOpen={viewResetPassword}/>
                  <button className="btn btn-primary account__btn account__btn--small" type="submit">Sign in</button>
              </form>
          )}
      </Form>
  );

};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LogInForm;
