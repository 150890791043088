import React,{ useEffect,useState } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthService from '../../Services/AuthService';
import { useHistory } from "react-router-dom";
import AuthCheck from '../../Services/AuthCheck';
import {connect} from "react-redux";
import {setActiveVenueId, setRoles, setUser} from "../../redux/actions/userActions";

const ProtectedRoute = ({dispatch, component: Component, user, roles, activeVenueId , ...restOfProps}) => {

    const history = useHistory();
    const [isLoaded, setLoaded] = useState(false);

    let isAuthenticated = localStorage.getItem("isAuthenticated");

    useEffect(()=>{
        history.listen(async (location)=>{
            await AuthCheck();
        });

        // SET THE USER DATA FROM LOCALSTORAGE
        dispatch(setUser(JSON.parse(localStorage.user)));
        dispatch(setRoles(JSON.parse(localStorage.user).roles));
        dispatch(setActiveVenueId(localStorage.activeVenueId));
    },[]);

    const firstLoad = async () => {
        if(!isLoaded){
        setLoaded(true);
        await AuthCheck();
    }};
    firstLoad();

    // if(localStorage.getItem('expire_at') !== null){
    //     let eat = new Date(localStorage.getItem('expire_at') * 1000);
    //     let now = new Date();
    //
    //     if(now.getTime() > eat.getTime()){
    //         // NOW IS NEWER THAN THE EAT. TOKEN EXPIRED
    //
    //         // ATTEMPT TO USE THE REFRESH TOKEN
    //
    //
    //         // UPDATE THE LOCAL STORAGE TO isAuthenticated = "N"
    //         AuthService.logout().then(response => {
    //             isAuthenticated = "N";
    //             return history.push("/login");
    //
    //         });
    //     }
    // }

    console.log("this", isAuthenticated);

    const loginLink = "/login?redirect="+window.location.href;
    return (
        <Route
            {...restOfProps}
            render={(props) =>
                (isAuthenticated === "Y") ? <Component {...props} /> : <Redirect to={loginLink} />
            }
        />
    );
};

export default connect(state => ({
    user : state.user.main,
    roles : state.user.roles,
    activeVenueId : state.user.activeVenueId
}))(ProtectedRoute);