/* eslint-disable */
import React, {useState,useEffect} from 'react';
import { Link,useParams } from 'react-router-dom';
import Http from "../../containers/App/Http";
import { useHistory } from "react-router-dom";
import {
    setRoles,
    setUser,
    setActiveVenueId
} from "../../redux/actions/userActions";
import {connect} from "react-redux";
import {Alert, Card, CardBody, Col, Row} from "reactstrap";

const PasswordReset = ({dispatch,user,activeVenueId}) => {
    const { token } = useParams();
    const [newPassword,setNewPassword]=useState(null);
    const history = useHistory();
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);

    useEffect(()=>{

    },[]);

    const handlePasswordUpdate = (e) => {
        e.preventDefault();
        let formData = new FormData(document.getElementById('formUpdatePassword'));
        Http.post("/auth/changeSelfPassword",formData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg + <Link to="/login"/>);
        });

    };

    return (

        <Row className="justify-content-center pb-2">
            <Col xs={12} md={3} >
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={12}>
                                <div className="account__head">
                                    <h3 className="account__title">bookWNY <span className="account__logo">UPDATE YOUR PASSWORD</span></h3>
                                    <h4 className="account__subhead subhead">Manage your business easily</h4>
                                </div>
                                <Alert color="danger" isOpen={!!errorMsg} className="p-3">
                                    {errorMsg}
                                </Alert>
                                <Alert color="success" isOpen={!!successMsg} className="p-3">
                                    {successMsg}
                                </Alert>
                                <form id="formUpdatePassword" onSubmit={handlePasswordUpdate}>
                                    <label htmlFor="newPassword" className="sr-only">New Password</label>
                                    <input type="password" name="newPassword" id="newPassword" className="form-control" placeholder="New Password" required=""/>
                                    <label htmlFor="confirm_newPassword" className="sr-only">Confirm New Password</label>
                                    <input type="password" name="confirm_newPassword" id="confirm_newPassword" className="form-control" placeholder="Confirm New Password" required="" />
                                    <input type="hidden" name="token" value={token}/>
                                    <button className="btn btn-primary btn-sm btn-block" type="submit">Update</button>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    );

};

export default connect(state => ({
    user : state.user.main,
    roles : state.user.roles,
    activeVenueId : state.user.activeVenueId
}))(PasswordReset);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
