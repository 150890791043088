// import React from "react";
// import {connect} from "react-redux";
import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {setVenueData, setVenueImages, setVenueMembers, setVenueSubscriptions} from "../redux/actions/venueActions";

export default class Venue{

    getVenueData(venueId){
        // FETCH THE VENUE INFO
        const formData = new FormData();

        return Http.post("/v/"+venueId+"/getVenueData",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;
            await store.dispatch(setVenueData(response.data));
            return response;
        });
    };

    getMembers(venueId,roles){
        // FETCH THE Users
        const formData = new FormData();
        formData.append('venueId',venueId);
        formData.append('roles',roles);

        return Http.post("/v/"+venueId+"/getMembers",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setVenueMembers(response.data.data));
            return response;
        });
    };

    addMember(venueId,formData){
        // FETCH THE Users
        formData.append('venueId',venueId);

        return Http.post("/u/addUserRole",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            // await store.dispatch(setVenueMembers(response.data.data));
            return response;
        });
    };

    inviteMember(formData){
        // formData.append('queryPageIndex',queryPageIndex);
        // formData.append('queryPageSize',queryPageSize);

        return Http.post("/users/inviteMemberToVenue",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    removeMemberAccess(formData){
        return Http.post("/u/dropUserAccess",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    getVenueStats(venueId,dateRangeStarting,dateRangeEnding){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('dateRangeStarting',new Date(dateRangeStarting).toLocaleDateString("en-US"));
        formData.append('dateRangeEnding',new Date(dateRangeEnding).toLocaleDateString("en-US"));

        return Http.post("/v/"+venueId+"/getVenueStats",formData).then(response => {
            return response;
        });
    };

    getVenueImages(venueId){
        // FETCH THE VENUE INFO
        const formData = new FormData();

        return Http.post("/v/"+venueId+"/getImages",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;
            await store.dispatch(setVenueImages(response.data.data));
            return response;
        });
    };

    changeVenueTier(venueId,tierId){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('venueId',venueId);
        // USES THE TIER ADVANCED ID
        formData.append('tierId',tierId);

        return Http.post("/v/"+venueId+"/changeVenueTier",formData).then(response => {
            return response;
        });
    };

    changeVenueStatus(venueId,status){
        // FETCH THE VENUE INFO
        const formData = new FormData();
        formData.append('venueId',venueId);
        // USES THE TIER ADVANCED ID
        formData.append('status',status);

        return Http.post("/v/"+venueId+"/changeVenueStatus",formData).then(response => {
            return response;
        });
    };

    // uploadImages(venueId,formData){
    //     return Http.post("/v/"+venueId+"/uploadImages",formData).then(response => {
    //         return response;
    //     });
    // }

    setFeaturedImage(formData){
        return Http.post(process.env.REACT_APP_BOOKWNY_INSTANCE_API_URL +"/api/v1/venueSetFeaturedImage?apiKey=TDAu9cgB062h4wNXMdikDF1nGIq3XhXq",formData).then(response => {
            return response;
        });
    }

    updateVenueData(venueId,formData){
        return Http.post("/v/"+venueId+"/updateVenue",formData).then(response => {
            return response;
        });
    }

    updateVenueAmenities(venueId,formData){
        return Http.post("/v/"+venueId+"/updateVenueAmenities",formData).then(response => {
            return response;
        });
    }

    getAvailableAmenities(venueId){
        return Http.post("/v/"+venueId+"/getAvailableAmenities").then(response => {
            return response;
        });
    }

    getVenueAmenities(venueId){
        return Http.post("/v/"+venueId+"/getVenueAmenities").then(response => {
            return response;
        });
    }

    requestVenueIndexing(venueId,formData){
        return Http.post("/v/"+venueId+"/requestUrlIndexing",formData).then(response => {
            return response;
        });
    }

    requestVenueIndexingNotifications(venueId,formData){
        return Http.post("/v/"+venueId+"/getIndexingNotificationStatus",formData).then(response => {
            return response;
        });
    }

    getSubscriptions(venueId){
        return Http.post("/v/"+venueId+"/getSubscriptions").then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setVenueSubscriptions(response.data.data));
            return response;
        });
    }

    updateVenueHoursOfOperations(venueId,formData){
        return Http.post("/v/"+venueId+"/updateVenueHoursOfOperations",formData).then(response => {
            return response;
        });
    }

    getHoursOfOperations(venueId){
        return Http.post("/v/"+venueId+"/getHoursOfOperations").then(response => {
            return response;
        });
    }
}