import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelVenue from '../../../../Models/Venue';
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../shared/components/Notification";


const MembersTable = ({ dispatch, venueMembers ,venueData,theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const vm = new ModelVenue();

    const fetchData = async () => {
        vm.getMembers(venueData.v_id).then(response=>{
            if (!response) {
                alert('failed to load members');
                return false;
            }
            return true;
        });
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof venueMembers !== 'undefined' || venueMembers!==null){
            setLoaded(true);
        }
    },[venueMembers]);

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[]);

    const removeMemberAccess = (member) => {
        let formData = new FormData();
        formData.append('userRoleId',member.ur_id);
        formData.append('venueId',member.v_id);
        formData.append('userId',member.u_id);

        vm.removeMemberAccess(formData).then(response => {
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Remove Access',response.data.msg);
                return;
            }
            showNotification(theme,'primary',rtl,'Remove Access',response.data.msg);

            vm.getMembers(venueData.v_id).then(response=>{
                if (!response) {
                    showNotification(theme,'danger',rtl,'Members',response.data.msg);
                    return false;
                }
                return true;
            });

        });
    };

    const columns =[
        {
            width: 300,
            Header: "Options",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-danger btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); removeMemberAccess(cell.row.original)}}>
                        Remove <FontAwesomeIcon icon={faUserSlash}/>
                    </a>
                )
            }
        },
        {
            Header: 'Role',
            accessor: 'r_display_name'
        },{
            Header: 'First',
            accessor: 'u_first',
            width: 150
        },{
            Header: 'Last',
            accessor: 'u_last'
        },{
            Header: 'Email',
            accessor: 'u_email'
        },{
            Header: 'Phone',
            accessor: 'u_main_phone'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (


            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />
                }
            </div>

    );
};

export default connect(state => ({
    venueData:state.venue.venueData,
    venueMembers:state.venue.members,
    rtl: state.rtl,
    theme: state.theme
}))(MembersTable);
