import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelVenue from '../../../../Models/Venue';
import ModelUser from '../../../../Models/User';
import { Alert, Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../../../src/containers/App/Http';
import {showNotification} from "../../../components/Notification";
import Cleave from "cleave.js/react";
import {CardElement,useElements,useStripe} from '@stripe/react-stripe-js';

const SubscribeVenueToTier = ({dispatch,view, user,venueData, rtl,theme}) => {
    const [modal, setModal] = useState(view);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const stripe = useStripe();
    const elements= useElements();
    const mu = new ModelVenue();
    const um = new ModelUser();

    useEffect(async () => {
    },[]);

    const toggle = async () => {
        await setModal(prevState => !prevState);
        // setError(false);
        console.log(modal);
        setSuccess(false);
    };

    const handleSubmit= () =>{
        document.querySelector("#formSubscribeVenueToTierFromToken button[type=submit]").click();
    };

    const continueSubmission=async (form)=>{
        form.preventDefault();
        setProcessing(true);
        let formData = new FormData(document.getElementById('formSubscribeVenueToTierFromToken'));

        const cardElement = elements.getElement("card");

        const {error,token} = await stripe.createToken(cardElement);
        // const {error, paymentMethod} = await stripe.createToken(cardElement);

        if(error){
            setError(error.message);
            return;
        }

        let subData = new FormData(document.getElementById("formSubscribeVenueToTierFromToken"));
        subData.append('stripeToken',token.id);
        subData.append('userId',user.u_id);

        Http.post("/sb/SubscribeVenueToTierFromToken",subData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            // RELOAD THE USER OBJECT INTO REDUX
            um.getUserInfo();



            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);
        });

    };

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="md">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Subscribe To Tier</h4>
                </div>

                <div className="modal__body">
                    {successMsg !== false &&
                    <>
                        <h4>Successfully added payment method</h4>
                        <Button color={"danger"} className={"btn-sm mb-0 mt-2"} onClick={toggle}>Close<FontAwesomeIcon icon="user"/></Button>
                    </>
                    }
                    {!successMsg &&
                    <form id="formSubscribeVenueToTierFromToken" className={"form--horizontal form"}
                          onSubmit={continueSubmission}>
                        <button type="submit" style={{'display': 'none'}}>Submit</button>

                        <Col xs={'12'}>
                            <Alert color="danger" isOpen={!!errorMsg}>
                                {errorMsg}
                            </Alert>
                            <Alert color="success" isOpen={!!successMsg}>
                                {successMsg}
                            </Alert>
                        </Col>


                        <div className="form__form-group mt-3">
                            <Col xs={'12'} className={'mt-3 mb-3'}>
                                <Alert color="info" className="p-3">
                                    <p><span className="bold-text">Information:</span>
                                        The payment method will be added to your account. If you prefer ACH payments, please contact our support team
                                    </p>
                                </Alert>
                                <CardElement/>
                            </Col>
                            <div className="col-sm-3">
                                <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit} disabled={errorMsg || processing}>Submit</button>
                            </div>
                        </div>

                    </form>
                    }
                </div>

            </Modal>
        </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    user : state.user.main,
    roles : state.user.roles,
    theme: state.theme,
    users:state.users.users,
    venueData:state.venue.venueData,
    venueMembers:state.venue.members
}))(SubscribeVenueToTier);