/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import AuthService from "../../Services/AuthService";
import {getUrlVars } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import {
    setRoles,
    setUser,
    setActiveVenueId
} from "../../redux/actions/userActions";
import {connect} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";

const LogIn = ({dispatch,user,activeVenueId}) => {

    const history = useHistory();

    const handleLogin = (loginCredentials) => {

        AuthService.login(loginCredentials.username, loginCredentials.password).then(
            async (response) => {
                if(response===false)
                    return;

                // SET USER AND ROLES
                dispatch(setUser(response.data.data.user));
                dispatch(setRoles(response.data.data.user.roles));
                if(localStorage.getItem("activeVenueId")===null)
                    await dispatch(setActiveVenueId(response.data.data.user.u_default_venue_id));
                else
                    await dispatch(setActiveVenueId(localStorage.getItem("activeVenueId")));

                // CHECK FOR REDIRECT LINK
                // Read a page's GET URL variables and return them as an associative array.
                let vars = getUrlVars();
                if(typeof vars !== "undefined" && vars !== null){
                    if(typeof vars['redirect'] !== "undefined"){
                        window.location.href = vars['redirect'];
                        return;
                    }
                }

                // IF USER HAS NO ACCESS TO VENUES THEN SHOW THE FIND YOUR VENUE OR CREATE A VENUE. Go To profile page
                if(response.data.data.user.u_default_venue_id === null)
                    history.push("/profile");
                else
                    history.push("/venue/"+localStorage.getItem("activeVenueId"));
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };
    {/*<div className="account">*/}
        {/*<div className="account__wrapper">*/}
            {/*<div className="account__card">*/}
                {/*<div className="account__head">*/}
                    {/*<h3 className="account__title">bookWNY <span className="account__logo">ADMIN</span></h3>*/}
                    {/*<h4 className="account__subhead subhead">Manage your business easily</h4>*/}
                {/*</div>*/}
                {/*<LogInForm onSubmit={handleLogin}/>*/}
            {/*</div>*/}
        {/*</div>*/}
    {/*</div>*/}

    const goToRegisterAccount = () => {
        window.location.href = process.env.REACT_APP_BOOKWNY_INSTANCE_API_URL + "/register?redirect=https://my.bookwny.com";
    };

    return (

        <Row className="justify-content-center pb-2 mt-5">
            <Col xs={12} md={6} >
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={6}>
                                <div className="account__head">
                                    <h3 className="account__title">bookWNY <span className="account__logo">ADMIN</span></h3>
                                    <h4 className="account__subhead subhead">Manage your business easily</h4>
                                </div>
                                <LogInForm onSubmit={handleLogin}/>

                            </Col>
                            <Col sm={6} className="border-left">
                                <h3>Why bookWNY?</h3>

                                <p>
                                    <ul className="text-decoration-none">
                                        <li>We help customers find your venue quickly and easily</li>
                                        <li>We provide Hosts a basic venue page for free. This includes one picture, basic description and limited additional details</li>
                                        <li>Upgrading your venue page is easy. Complete a “List Your Business” form and we will contact you with next steps!</li>
                                    </ul>
                                </p>
                                <button className="btn btn-secondary" type="submit" onClick={goToRegisterAccount}>Create an Account</button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    );

};

export default connect(state => ({
    user : state.user.main,
    roles : state.user.roles,
    activeVenueId : state.user.activeVenueId
}))(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles
