import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import ModalChangeStatus from "./ModalChangeStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle, faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid';
import {connect} from "react-redux";


const VenueStatus = ({dispatch,venueData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStatus,setActiveStatus] = useState("Not Available");

  useEffect(()=>{
      if(venueData.v_active == 1)
          setActiveStatus("Active");
      else if(venueData.v_active == 0)
          setActiveStatus("Inactive");
  },[venueData]);

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget  p-3">
          <div className="card__title">
            <h5 className="bold-text">Venue Status</h5>
          </div>
          <div className="card__body">
              {(activeStatus === "Not Available" || activeStatus === 'Inactive')
                  ? <h3><FontAwesomeIcon icon="times-circle" style={{color:'#FF0000'}}/> {activeStatus}</h3>
                  : <h3><FontAwesomeIcon icon="check-circle" style={{color:'#adff2f'}}/> {activeStatus}</h3>
              }
              <ModalChangeStatus/>
          </div>


        </CardBody>
      </Card>
    </Col>
  );
};

export default connect(state => ({
    venueData:state.venue.venueData
}))(VenueStatus);