import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {connect} from "react-redux";
import { faChartLine,faUser ,faDollarSign,faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Submissions from "../../Submissions";
import { Col, Container, Row,Card,CardBody } from 'reactstrap';
import {
    setActiveVenueId
} from "../../../redux/actions/userActions";
import { useHistory } from "react-router-dom";

const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,

  dispatch,
  venueData,
    activeVenueId,
    roles,
    user
}) => {

  // const venueId = (typeof venueData === "undefined") ? "" : venueData.v_id;
  const venueId = (localStorage.getItem('activeVenueId')===null) ? "" : localStorage.getItem('activeVenueId');
    const history = useHistory();

  useEffect(()=>{
      // if(typeof activeVenueId === "undefined" || activeVenueId ===null)
      //     return;
      // document.getElementById("activeVenueRole").value=activeVenueId;
  },[activeVenueId]);

  const changeActiveVenueId = (e)=>{
      dispatch(setActiveVenueId(e.target.value));
      history.push("/venue/"+e.target.value);
  };

  return (
      <div className="sidebar__content">
          {typeof roles !== "undefined" &&
              <ul className="sidebar__block">
                  <Col xs={12} className="form">
                      <select id="activeVenueRole" className={"form-control-sm col-12 form-control"} value={activeVenueId} onChange={changeActiveVenueId}>
                          {roles!== null && roles.map((role,i)=>(
                              <option key={i} value={role.v_id}>{role.v_name} | {role.r_display_name}</option>
                          ))}
                      </select>
                  </Col>
              </ul>
          }
          {typeof roles !== "undefined" &&

              <ul className="sidebar__block">
                  <SidebarLink icon="home" title="Home" route={"/venue/" + venueId} onClick={onClick}/>
                  <SidebarLink title="Submissions" icon="file" route="/Submissions" onClick={onClick}/>
                  <SidebarLink icon={faChartLine} title="Performance" route={"/performance/" + venueId} onClick={onClick}/>
                  <SidebarLink icon="pencil-alt" title="Edit" route={"/edit/" + venueId} onClick={onClick}/>
                  <SidebarLink icon="image" title="Photos" route={"/photos/" + venueId} onClick={onClick}/>
                  <SidebarLink icon={faUser} title="Users" route={"/users/" + venueId} onClick={onClick}/>
                  <SidebarLink icon={faDollarSign} title="Subscriptions" route={"/subscriptions/" + venueId} onClick={onClick}/>
                  {/*<SidebarLink title="Reporting" icon="archive" route="/reporting" onClick={onClick} />*/}
              </ul>

          }



      </div>
  )
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default connect(state => ({
    venueData:state.venue.venueData,
    user : state.user.main,
    activeVenueId : state.user.activeVenueId,
    roles : state.user.roles
}))(SidebarContent);
