import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import modelVenue from '../../Models/Venue';
import { useParams } from 'react-router-dom';
import { getVenueData ,showVenueMenu} from '../../redux/actions/venueActions';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../shared/components/Panel";
import {showNotification} from "../../shared/components/Notification";
import HoursOfOperations from "./components/HoursOfOperations";
import Amenities from "./components/Amenities";

const VenueEdit = ({ dispatch, venueData,rtl,theme }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new modelVenue();
    const [activeTab, setActiveTab] = useState('1');


    // if(venueData.vt_ids == null || venueData.vt_ids.length === 0 || typeof venueData.vt_ids === "undefined")
    //     return;



    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        dispatch(showVenueMenu(true));

        if(!isLoaded){
            vm.getVenueData(venueId).then(async response => {

                if (response.data.status === 'failed') {
                    alert('failed');
                    return false;
                }


                await setIsLoaded(true);
                return true;
            });
        }

        if(isLoaded){
            Object.keys(venueData).forEach(key => {
                document.querySelectorAll('input[name='+key+']').forEach((el)=>{
                    el.value = venueData[key];
                });
                document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                    el.value = venueData[key];
                });
            });
            document.querySelector('input[type=checkbox][name=v_outdoor_space_available]').checked = venueData.v_outdoor_space_available === 'Y';


            let venueTypeIds = venueData.vt_ids.split(",");
            // document.querySelectorAll('input[type=checkbox][name*=vt_ids][value='+id.trim()+']').forEach((x)=>console.log(x))
            venueTypeIds.forEach(function(id){

                // debugger
                document.querySelectorAll('input[type=checkbox][name*=vt_ids]').forEach((vtId)=>{

                    if(vtId.value === id.trim())
                        vtId.checked =true;
                });
                //.checked =true;
                // $('#venueEditForm [name*=vt_ids][value='+id.trim()+']').prop('checked', true);

                // document.querySelectorAll('input[type=checkbox]');
            });

        }



    },[venueId,isLoaded]);

    const updateVenue = (e) =>{
        e.preventDefault();
        let formData = new FormData(document.getElementById('venueEditForm'));
        // venue type ids
        let vt_ids = "";
        let comma = "";
        if(document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').length > 0){
            document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').forEach(function(x){
                vt_ids += comma + x.value;
                comma = ",";
            });
        }
        formData.append('vt_ids',vt_ids);

        let v_outdoor_space_available = "N";
        if(document.querySelectorAll('input[type=checkbox][name=v_outdoor_space_available]:checked').length > 0){
            v_outdoor_space_available = 'Y';
        }
        formData.append('v_outdoor_space_available',v_outdoor_space_available);

        vm.updateVenueData(venueId,formData).then((response)=>{
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Update Venue',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update Venue',response.data.msg);
            return;
        });

    };



    return (

        isLoaded && (
            <Container className="dashboard">
                <Row className="justify-content-center">
                    <Panel sm={8}  title={venueData.v_name} refreshFunction="">
                        <div>
                            <div className="tabs tabs--bordered-top">
                                <div className="tabs__wrap">
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>Main</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => toggle('4')}>Amenities</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => toggle('5')}>Hours of Operation</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <div className={"col-12 p-0"}>
                                                <form id="venueEditForm" className={"form--horizontal form"}>
                                                    <div className="title">
                                                        <h4>Overview</h4>
                                                    </div>
                                                    <div className="form__form-group row mt-3 mb-1">
                                                        <label htmlFor="v_name" className="col-12 col-sm-3 form__form-group-label col-form-label">Name</label>
                                                        <div className="col-sm-9">
                                                            <input type="text" className="form-control-sm" id="v_name" name="v_name" />
                                                        </div>
                                                    </div>

                                                    <div className="form__form-group row mt-2 mb-1">
                                                        <label htmlFor="v_address" className="col-sm-3 form__form-group-label col-form-label">Address</label>
                                                        <div className="col-sm-9">
                                                            <input type="text" className="form-control-sm" id="v_address" name="v_address" disabled={'disabled'}/>
                                                        </div>
                                                    </div>
                                                    <div className="form__form-group row mt-1 mb-1">
                                                        <label htmlFor="v_city" className="col-12 col-sm-2 form__form-group-label col-form-label">City</label>
                                                        <div className="col-sm-3">
                                                            <input type="text" className="form-control-sm" id="v_city" name="v_city" disabled={'disabled'}/>
                                                        </div>
                                                        <label htmlFor="v_state" className="col-12 col-sm-2 form__form-group-label col-form-label">State</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_state" name="v_state" disabled={'disabled'}/>
                                                        </div>
                                                        <label htmlFor="v_zip" className="col-12 col-sm-1 form__form-group-label col-form-label">Zip</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_zip" name="v_zip" disabled={'disabled'}/>
                                                        </div>
                                                        <hr className={'row col-12'}/>
                                                    </div>

                                                    <div className="title">
                                                        <h4>Capacity</h4>
                                                        <small>Set your venues capacity.</small>
                                                    </div>

                                                    <div className="form__form-group row mt-3">
                                                        <label htmlFor="v_max_capacity" className="col-12 col-sm-2 form__form-group-label col-form-label">Max Capacity</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_max_capacity" name="v_max_capacity" />
                                                        </div>
                                                        <label htmlFor="v_sitting" className="col-12 col-sm-2 form__form-group-label col-form-label">Sitting</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_sitting" name="v_sitting"  />
                                                        </div>
                                                        <label htmlFor="v_standing" className="col-12 col-sm-2 form__form-group-label col-form-label">Standing</label>
                                                        <div className="col-sm-2">
                                                            <input type="text" className="form-control-sm" id="v_standing" name="v_standing"  />
                                                        </div>
                                                        <hr className={'row col-12'}/>

                                                    </div>



                                                    <div className="title">
                                                        <h4>Details</h4>
                                                    </div>


                                                    <div className="form__form-group row mt-3">
                                                        <label htmlFor="v_website" className="col-12 col-sm-2 form__form-group-label col-form-label">Website</label>
                                                        <div className="col-sm-4">
                                                            <input type="text" className="form-control-sm" id="v_website" name="v_website" />
                                                        </div>
                                                        <label htmlFor="v_phone" className="col-12 col-sm-2 form__form-group-label col-form-label">Phone</label>
                                                        <div className="col-sm-4">
                                                            <input type="text" className="form-control-sm" id="v_phone" name="v_phone" />
                                                        </div>
                                                    </div>
                                                    <div className="row form__form-group">
                                                        <div className="form__form-group col-sm-6">
                                                            <label className={"form__form-group-label"} htmlFor="v_description">Description</label>
                                                            <textarea className="form-control-sm" id="v_description" name="v_description" rows="9"/>
                                                        </div>
                                                        <div className="form__form-group col-sm-6">
                                                            <label className={"form__form-group-label"} htmlFor="v_pricing">Pricing</label>
                                                            <textarea className="form-control-sm" id="v_pricing" name="v_pricing" rows="9"/>
                                                        </div>
                                                        <hr className={'row col-12'}/>
                                                    </div>

                                                    <div className="title">
                                                        <h4>Your Venue Types</h4>
                                                        <small>Set all types applicable to your business.</small>
                                                    </div>

                                                    <div className="col-md-12 p-0" id="venue_types_checkboxes_wrapper">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="1" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Hotels
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="2" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Restaurant
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="3" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Bar/Brewery
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="4" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper">
                                                            Country Club
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="5" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper"> Banquet/Party Hall</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="6" name="vt_ids[]"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="venue_types_checkboxes_wrapper"> Others</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 p-0">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="Y" name="v_outdoor_space_available" id="v_outdoor_space_available"/>
                                                        <label className="checkbox-btn__label form-check-label" htmlFor="v_outdoor_space_available">Has Outdoor Space Available</label>
                                                    </div>
                                                </div>

                                                <div className="form__form-group row mt-3">
                                                    <hr className={'row col-12'}/>
                                                </div>

                                                <button className="btn btn-primary" type="button" onClick={updateVenue}>Update</button>


                                            </form>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Amenities/>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <HoursOfOperations/>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(VenueEdit);