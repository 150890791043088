import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import modelVenue from '../../Models/Venue';
import { useParams } from 'react-router-dom';
import { getVenueData ,showVenueMenu} from '../../redux/actions/venueActions';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../shared/components/Panel";
import InviteMembers from "./components/InviteMembers";
import {showNotification} from "../../shared/components/Notification";
import MembersTable from "./components/MembersTable";

const Users = ({ dispatch, venueData,rtl,theme }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new modelVenue();
    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        dispatch(showVenueMenu(true));

        if(!isLoaded){
            vm.getVenueData(venueId).then(async response => {

                if (response.data.status === 'failed') {
                    alert('failed');
                    return false;
                }


                await setIsLoaded(true);
                return true;
            });
        }

    },[venueId,isLoaded]);

    return (

        isLoaded && (
            <Container className="dashboard">
                <Row className="justify-content-center">
                    <Panel sm={8}  title={venueData.v_name} refreshFunction="">
                        <div>
                            <Col xs="12">
                                <Row>
                                    <div className={"col-12"}>
                                        <InviteMembers/>
                                    </div>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <hr/>
                                        <MembersTable/>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Panel>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(Users);