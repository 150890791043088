import { handleActions } from 'redux-actions';
import {
    setUser,
    setRoles,
    setActiveVenueId
} from '../actions/userActions';

const defaultState = {
    main:null,
    roles:null,
    activeVenueId:null
};

export default handleActions(
  {
    [setUser](state, { payload }) {
        return {...state, main: payload};
    },[setRoles](state, { payload }) {
        return {...state, roles: payload};
    },[setActiveVenueId](state, { payload }) {
        localStorage.setItem("activeVenueId",payload);
        return {...state, activeVenueId: payload};
    }
  },
  defaultState,
);
