import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import PasswordReset from '../PasswordReset/index';
import LogOut from '../LogOut/index';
import Reporting from '../Reporting/index';
import ReportView from '../Reporting/components/ReportView';
import Venue from '../Venue/index';
import VenueEdit from '../VenueEdit/index';
import VenuePhotos from '../VenuePhotos/index';
import Submissions from '../Submissions/index';
import ProtectedRoute from './ProtectedRoute';
import Performance from '../Performance';
import Profile from '../Profile';
import Users from "../Users";
import AcceptVenueInvitation from "../User/AcceptVenueInvitation";
import Subscriptions from "../Subscriptions";

// const Pages = () => (
//   <Switch>
//     <Route path="/pages/one" component={ExamplePageOne} />
//   </Switch>
// );

const venueId = localStorage.getItem('activeVenueId');

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
        {/*<Route path="/pages" component={Pages} />*/}
        <Route path="/reporting" component={Reporting} />
        <Route path="/reportView/:reportId" component={ReportView} />
        <Route path="/submissions" component={Submissions} />




        <Route path="/profile" component={Profile} />
        <Route path="/venue/:venueId" component={Venue} />
        <Route path="/performance/:venueId" component={Performance} />
        <Route path="/edit/:venueId" component={VenueEdit} />
        <Route path="/photos/:venueId" component={VenuePhotos} />
        <Route path="/users/:venueId" component={Users} />
        <Route path="/subscriptions/:venueId" component={Subscriptions} />

        <Route path="/invitations/:id/:token" component={AcceptVenueInvitation} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/passwordReset/:token" component={PasswordReset} />
        <Route exact path="/logout" component={LogOut} />
        <ProtectedRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
