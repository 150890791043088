import React, { useEffect,useState,useRef  } from 'react';
import { connect } from 'react-redux';
import ModelVenue from '../../../Models/Venue';
import ModelUser from '../../../Models/User';
import { Alert, Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../../src/containers/App/Http';
import {showNotification} from "../../components/Notification";
import Cleave from "cleave.js/react";
import Select from 'react-select';

import AsyncSelect from 'react-select/async';

const RequestAccess = ({dispatch, isOpen,toggle,user,venueData, rtl,theme,venueMembers}) => {
    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [roleOptions,setRoleOptions] = useState([""]);
    const [input,setInput] = useState(null);
    const [selectedVenue,setSelectedVenue] = useState(null);
    const mu = new ModelVenue();
    const um = new ModelUser();

    useEffect(async () => {
        setRoleOptions([{value: 'REGU', label: 'User'},{value:'VADM' , label: 'Venue Admin'}]);
    },[]);


    const handleSubmit= () =>{
        document.querySelector("#FormRequestAccess button[type=submit]").click();
    };

    const continueSubmission=async (form)=>{
        form.preventDefault();
        setProcessing(true);
        let formData = new FormData(document.getElementById('FormRequestAccess'));

        Http.post("/u/addUserRole",formData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            // RELOAD THE USER OBJECT INTO REDUX
            um.getUserInfo();



            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);
        });

    };

    const onSelect = (venue) => {
        setSelectedVenue(venue);
    };

    const handleInputChange = (newValue: string) => {
        // WAIT FOR 2 CHARS TO EXEC
        if(newValue.length < 2 )
            return;
        const inputValue = newValue.trim();
        setInput(inputValue);
        return inputValue;
    };

    const loadOptions = ( inputValue, callback: (options) => void ) => {

        // RUN ASYNC HERE
        let formData = new FormData();
        formData.append('search',inputValue);
        Http.post('/vs/searchForVenues',formData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            setProcessing(false);
            setError(false);
            callback(response.data.data);
        });
    };

    return (

            <>
                <Modal isOpen={isOpen} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} id="ModalRequestAccess" modalClassName={`${rtl.direction}-support`} size="md">
                    <div className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            aria-label="modal__close-btn"
                            type="button"
                            onClick={toggle}
                        />
                        <h4 className="text-modal  modal__title">Request Access</h4>
                    </div>

                    <div className="modal__body">
                        {successMsg !== false &&
                            <>
                                <h4>Successfully added payment method</h4>
                                <Button color={"danger"} className={"btn-sm mb-0 mt-2"} onClick={toggle}>Close<FontAwesomeIcon icon="user"/></Button>
                            </>
                        }
                        {!successMsg &&
                            <form id="FormRequestAccess" className={"form--horizontal form"} onSubmit={continueSubmission}>
                                <button type="submit" style={{'display': 'none'}}>Submit</button>

                                <Col xs={'12'}>
                                    <Alert color="danger" isOpen={!!errorMsg}>
                                        {errorMsg}
                                    </Alert>
                                    <Alert color="success" isOpen={!!successMsg}>
                                        {successMsg}
                                    </Alert>
                                </Col>


                                <div className="form__form-group">
                                    <Col xs={'12'} className={'mb-3'}>
                                        <Alert color="info" className="p-3">
                                            <p><span className="bold-text">Information: </span>
                                                Request will be reviewed and you'll be notified once access is granted
                                            </p>
                                        </Alert>
                                        <div>
                                            <label>Search Venues:</label>
                                            <small style={{display:'block'}}>Start typing venue name and select from list.</small>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadOptions}
                                                defaultOptions
                                                name="venueId"
                                                onInputChange={handleInputChange}
                                                onChange={onSelect}
                                                menuPortalTarget={document.body}
                                                styles={{menuPortal:base=>({...base,zIndex:9999})}}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <label>Select Role:</label>
                                            <small style={{display:'block'}}>Select from the dropdown options.</small>
                                            <Select

                                                isSearchable={true}
                                                name="roleId"
                                                options={roleOptions}
                                            />
                                        </div>
                                    </Col>
                                    <div className="col-sm-3">
                                        <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit} disabled={errorMsg || processing}>Submit</button>
                                    </div>
                                </div>

                            </form>
                        }
                    </div>

                </Modal>
            </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    user : state.user.main,
    roles : state.user.roles,
    theme: state.theme,
    users:state.users.users,
    venueData:state.venue.venueData,
    venueMembers:state.venue.members
}))(RequestAccess);