import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelVenue from '../../../../Models/Venue';
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../shared/components/Notification";
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CreateSubscription from "./CreateSubscription";
import UpgradePlan from "../../../../shared/components/UpgradePlan";


const SubscriptionsComponent = ({ dispatch, venueSubscriptions ,venueData,theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const vm = new ModelVenue();

    const fetchData = async () => {
        vm.getSubscriptions(venueData.v_id).then(response=>{
            if (!response) {
                alert('failed to subscriptions');
                return false;
            }
            return true;
        });
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof venueSubscriptions !== 'undefined' || venueSubscriptions!==null){
            setLoaded(true);
        }
    },[venueSubscriptions]);

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[]);

    const columns =[
        // {
        //     width: 300,
        //     Header: "Options",
        //     Cell: ({ cell }) => {
        //         // debugger
        //         return (
        //             <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-danger btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); removeMemberAccess(cell.row.original)}}>
        //                 Remove <FontAwesomeIcon icon={faUserSlash}/>
        //             </a>
        //         )
        //     }
        // },
        {
            Header: 'Subscription Id',
            accessor: 's_stripe_id'
        },{
            Header: 'Email',
            accessor: 'u_email',
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (



    <>
        <Col xs={'12'}>
            <UpgradePlan/>
        </Col>
        <Col xs={'12'} className={'mt-4'}>
            {isTableLoaded &&
                <DataReactTable reactTableData={ tableStructure } />
            }
        </Col>
    </>

    );
};

export default connect(state => ({
    venueData:state.venue.venueData,
    venueMembers:state.venue.members,
    venueSubscriptions:state.venue.subscriptions,
    rtl: state.rtl,
    theme: state.theme
}))(SubscriptionsComponent);
