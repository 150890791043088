import React,{ useEffect,useState } from 'react';
import Panel from "../../../shared/components/Panel";
import DataReactTable from "./components/DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelSubmissions from '../../../Models/Submissions';
import ModalViewSubmission from "./components/ViewSubmission";
import { faEye,faChartLine,faUser ,faHotel,faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const SubmissionsTable = ({ dispatch,activeVenueId, venues, submissions,title,subTitle,options }) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const um = new ModelSubmissions();
    const {
        allowModalView,
        startDate,
        endDate
    } = options;

    const fetchData = async () => {

        let options =new FormData();
        options.append("venueId",activeVenueId);

        if(typeof startDate !== "undefined" && startDate !== null)
            options.append("startDate",new Date(startDate).toLocaleDateString("en-US"));
        if(typeof endDate !== "undefined" && endDate !== null)
            options.append("endDate",new Date(endDate).toLocaleDateString("en-US"));


        um.getSubmissionsV2(options).then(response=>{
            if (!response) {
                alert('failed to load submissions');
                return false;
            }
            return true;
        });
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof submissions !== 'undefined' || submissions!==null)
            setLoaded(true);

    },[submissions]);

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[activeVenueId,startDate, endDate]);

    const handleEdit = async (submission) => {
        await setSelectedSubmission(false);
        await setSelectedSubmission(submission);
    };

    const columns1 =[
        {
            width: 300,
            Header: "View",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    allowModalView ===true && <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-primary btn-sm m-0 mr-2 pb-1 pt-1 pl-2 pr-1"} onClick={e => {e.preventDefault(); handleEdit(cell.row.original)}}><FontAwesomeIcon icon={faEye}/></a>
                )
            }
        },{
            Header: 'Name',
            accessor: 'name',
            width: 150
        },{
            Header: 'Budget',
            accessor: 'qr_event_budget'
        },{
            Header: '# of People',
            accessor: 'qr_num_of_people'
        },{
            Header: 'Event Date',
            accessor: 'qr_event_date'
        },{
            Header: 'Type',
            accessor: 'et_name'
        },{
            Header: 'Email',
            accessor: 'qr_email'
        },{
            Header: 'Created Date',
            accessor: 'qr_created_date'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns1
    };

    return (

        <Panel xl={12} lg={12} md={12} title={title} subhead={subTitle} refreshFunction={fetchData}>
            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />
                }
                {submission &&
                    <ModalViewSubmission submission={submission}/>
                }

            </div>
        </Panel>

    );
};

export default connect(state => ({
    venues:state.venues.venues,
    activeVenueId:state.user.activeVenueId,
    submissions:state.submissions.submissions
}))(SubmissionsTable);
