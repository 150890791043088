import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {setAllUsers} from "../redux/actions/usersActions";
import {setRoles, setUser} from "../redux/actions/userActions";

export default class User{

    getUserInfo(){
        // FETCH THE Users
        const formData = new FormData();
        // const formData = new FormData();

        return Http.post("/u/getUserProfile",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            this.setUserRedux(response.data.data,response.data.data.roles);
            return response;
        });
    };

    updateUser(formData){

        return Http.post("/u/updateUser",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setUser(response.data.data));
            return response;
        });
    };

    acceptVenueInvitation(formData){
        return Http.post("/u/acceptVenueAccessInvitation",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            this.setUserRedux(response.data.data,response.data.data.roles);
            return response;
        });
    }

    async setUserRedux(userObject,rolesObject){
        if(typeof userObject !== "undefined")
            await store.dispatch(setUser(userObject));
        if(typeof rolesObject !== "undefined")
            await store.dispatch(setRoles(rolesObject));
    };

}