import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelSubmissions from '../../../../Models/Submissions';
import { Alert, Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';

const ModalViewSubmission = ({dispatch, rtl,submission}) => {

    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const mu = new ModelSubmissions();

    useEffect(async () => {
        toggle().then(()=>{
            let vef = document.getElementById('viewSubmissionForm');
            Object.keys(submission).forEach(key => {
                vef.querySelectorAll('input[name='+key+']').forEach((el)=>{
                    el.value = submission[key];
                });
                vef.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                    el.value = submission[key];
                });
            });
        });
    },[submission]);

    const toggle = async () => {
        if(modal===false){
            setError(false);
            setSuccess(false);

        }

        await setModal(prevState => !prevState);
    };

    const handleSubmit= (form) =>{
        form.preventDefault();
        let formData = new FormData(document.getElementById('viewSubmissionForm'));

        // mu.updateUser(formData).then(response => {
        //     if (response.data.status === 'failed') {
        //         setError(response.data.msg);
        //         setSuccess(false);
        //         return false;
        //     }
        //
        //     setError(false);
        //     setSuccess(response.data.msg);
        //     mu.getUsers().then(response=>{
        //         if (!response) {
        //             alert('failed to load users');
        //             return false;
        //         }
        //         return true;
        //     });
        // });
    };

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="xl">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">View Submission</h4>
                </div>
                <form id="viewSubmissionForm" className={"form--horizontal form"} onSubmit={handleSubmit}>

                <div className="modal__body">

                    <Alert
                        color="danger"
                        isOpen={!!errorMsg}
                    >
                        {errorMsg}
                    </Alert>
                    <Alert
                        color="success"
                        isOpen={!!successMsg}
                    >
                        {successMsg}
                    </Alert>
                        <div className={'row'}>
                            {/*<div className="form__form-group mt-3">*/}
                                {/*<label htmlFor="u_id" className="col-sm-2 form__form-group-label col-form-label">Event Type</label>*/}
                                {/*<div className="col-sm-4">*/}
                                    {/*<input type="text" className="form-control-sm" id="u_id" name="u_id" value={submission.et_name} readOnly={true}/>*/}
                                {/*</div>*/}
                            {/*</div>*/}

                            <div className="form__form-group mt-1">
                                <label htmlFor="u_first" className="col-sm-2 form__form-group-label col-form-label">ID</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm form-control" id="qr_id" name="qr_id"/>
                                </div>
                                <label htmlFor="name" className="col-sm-2 form__form-group-label col-form-label">Name</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm form-control" id="name" name="name"/>
                                </div>
                            </div>
                            <div className="form__form-group mt-1">
                                <label htmlFor="qr_event_date" className="col-sm-2 form__form-group-label col-form-label">Event Date</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm form-control" id="qr_event_date" name="qr_event_date" />
                                </div>
                                <label htmlFor="qr_num_of_people" className="col-sm-2 form__form-group-label col-form-label">Attendees</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm form-control" id="qr_num_of_people" name="qr_num_of_people" />
                                </div>
                            </div>
                            <div className="form__form-group mt-1">
                                <label htmlFor="u_first" className="col-sm-2 form__form-group-label col-form-label">Type</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm form-control" id="et_name" name="et_name"/>
                                </div>
                            </div>
                            <div className="form__form-group mt-3">
                                <label htmlFor="qr_email" className="col-sm-2 form__form-group-label col-form-label">Email</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm form-control" id="qr_email" name="qr_email" />
                                </div>
                                <label htmlFor="qr_created_date" className="col-sm-2 form__form-group-label col-form-label">Created</label>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control-sm form-control" id="qr_created_date" name="qr_created_date" />
                                </div>
                            </div>
                            <div className="form__form-group mt-3">
                                <label htmlFor="qr_message" className="col-sm-2 form__form-group-label col-form-label">Message</label>
                                <div className="col-sm-12">
                                    <textarea className="form-control-sm form-control" id="qr_message" name="qr_message"></textarea>
                                </div>

                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className={'col-12 text-right'}>
                                {/*<button className="btn btn-primary btn-sm" type="submit">Update</button>*/}
                                <button className="btn btn-danger btn-sm" type="button" onClick={toggle}>Close</button>
                            </div>
                        </div>



                </div>
                </form>

            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting:state.venue.dateRangeStarting,
    users:state.users.users,
}))(ModalViewSubmission);