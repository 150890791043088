import React,{ useEffect,useState } from 'react';
import Panel from "../../../../shared/components/Panel";
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelSubmissions from '../../../../Models/Submissions';
import ModalViewSubmission from "../ViewSubmission";

const SubmissionsTable = ({ dispatch, venues, submissions }) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const um = new ModelSubmissions();

    const fetchData = async () => {
        um.getSubmissions().then(response=>{
            if (!response) {
                alert('failed to load submissions');
                return false;
            }
            return true;
        });
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof submissions !== 'undefined' || submissions!==null){
            setLoaded(true);
        }
    },[submissions]);

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[]);

    const handleEdit = async (submission) => {
        await setSelectedSubmission(false);
        await setSelectedSubmission(submission);
    };

    const columns1 =[
        {
            width: 300,
            Header: "Edit",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-primary btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); handleEdit(cell.row.original)}}>
                        View
                    </a>
                )
            }
        },{
            Header: 'Name',
            accessor: 'name',
            width: 150
        },{
            Header: 'Budget',
            accessor: 'qr_event_budget'
        },{
            Header: 'Number of People',
            accessor: 'qr_num_of_people'
        },{
            Header: 'Event Date',
            accessor: 'qr_event_date'
        },{
            Header: 'Event Type',
            accessor: 'et_name'
        },{
            Header: 'Last Name',
            accessor: 'qr_last_name'
        },{
            Header: 'Email',
            accessor: 'qr_email'
        },{
            Header: 'Created Date',
            accessor: 'qr_created_date'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns1
    };

    return (

        <Panel xl={12} lg={12} md={12} title="submissions" subhead="submissions" refreshFunction={fetchData}>
            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />
                }
                {submission &&
                    <ModalViewSubmission submission={submission}/>
                }

            </div>
        </Panel>

    );
};

export default connect(state => ({
    venues:state.venues.venues,
    submissions:state.submissions.submissions
}))(SubmissionsTable);
