import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import { faChartLine,faUser ,faHotel,faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelUser from "../../../../Models/User";
import {showNotification} from "../../../../shared/components/Notification";
import DataReactTable from "./DataReactTable";
import RequestAccess from "../../../../shared/components/RequestAccess";

const rolesImg = `${process.env.PUBLIC_URL}/img/roles.png`;

const MyVenues = ({ dispatch,venueData, roles,user,theme,rtl }) => {

    const [loaded,setLoaded]=useState(false);
    const [hasRoles,setHasRoles]=useState(false);
    const [viewRequestAccess,setViewRequestAccess] = useState(false);

    const um = new ModelUser();

    useEffect(()=>{

        // um.getUserInfo().then(async response => {
        //     if (response.data.status === 'failed') {
        //         alert('failed');
        //         return false;
        //     }
        // });

    },[]);

    useEffect(()=>{
        // loadVariablesIntoForm();
        setLoaded(true);

        if(typeof roles === "undefined" || roles === null)
            return;

        setHasRoles(true);
    },[user,roles]);

    const toggle=() => {
        setViewRequestAccess(prevState=>!prevState);
    };

    const toggleRequestAccess = ()=>{
        setViewRequestAccess(true);
    };

    const columns =[
        {
            Header: 'Name',
            accessor: 'v_name',
            width: 150
        },{
            Header: 'Role',
            accessor: 'r_display_name'
        },{
            Header: 'Added',
            accessor: 'ur_date_added'
        }
        // ,{
        //     width: 300,
        //     Header: "Artist Name",
        //     accessor: "bob",
        //     Cell: ({ cell }) => {
        //         // debugger
        //         return (
        //             <button type={"button"} value={cell.row.values.name} onClick={() => {redirect("bob")}}>
        //                 {cell.row.values.v_name}
        //             </button>
        //         )
        //     }
        // }
    ];

    const tableStructure = {
        tableHeaderData: columns,
        tableRowsData: roles
    };

    //IF NO VENUES USE A DIFF VIEW TO ADD VENUES TO ACCOUNT
    return (
        loaded === true &&
            <Col xs={12} md={6} >
                <Card>

                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">My Venues  <FontAwesomeIcon icon={faHotel}/></h5>
                            <h5 className="subhead">Use the pop up below to request access to your venues.</h5>
                        </div>
                        {hasRoles &&
                            <Row>
                                <Col md={12}>
                                    <DataReactTable reactTableData={ tableStructure } />
                                </Col>
                            </Row>
                        }
                        {!hasRoles &&
                            <Row>
                                <Col md={12}>
                                    <Row className="justify-content-center">
                                        <Col sm={6} className="text-center">
                                            <img className="" src={rolesImg} alt="" style={{maxWidth:'250px'}}/>
                                        </Col>
                                        <Col sm={6}>
                                            <p>You currently do not have access to any venues. Please request access to you venue by clicking on the button below</p><br/>
                                            <button className="btn btn-secondary" onClick={toggleRequestAccess}>Request Access</button>
                                            <RequestAccess toggle={toggle} isOpen={viewRequestAccess}/>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                </Card>
            </Col>
    );
}

export default connect(state => ({
    venueData:state.venue.venueData,
    roles : state.user.roles,
    user : state.user.main,
    rtl: state.rtl,
    theme: state.theme
}))(MyVenues);