import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelVenue from '../../../Models/Venue';
import { Alert, Button,Row,Col, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Http from '../../../../src/containers/App/Http';
import {showNotification} from "../../components/Notification";
import Cleave from "cleave.js/react";
import CardBasic from "./components/CardBasic";
import CardSpecial from './components/CardSpecial';
import CardPro from './components/CardPro';
import CardPremium from './components/CardPremium';
import AddPaymentMethod from '../AddPaymentMethod';
import {CardElement,useElements,useStripe} from '@stripe/react-stripe-js';
import ModelUser from '../../../Models/User';

const UpgradePlan = ({dispatch, user,users,venueData, rtl,theme,venueMembers}) => {
    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [products,setProducts] = useState(false);
    const [prices,setPrices] = useState(false);
    const [useCustomPricing,setUseCustomPricing] = useState('N');
    const stripe = useStripe();
    const elements= useElements();
    const mu = new ModelVenue();
    const um = new ModelUser();

    useEffect(async () => {
        // CHECK IF THE USER HAS CARD PAYMENT METHOD
    },[]);

    useEffect(async () => {


    },[user]);

    useEffect(async () => {
        // load the products
        // Http.post("/sb/getProducts").then(async response => {
        //     if (response.data.status === 'failed') {
        //         showNotification(theme,'danger',rtl,'Get Products',response.data.msg);
        //         return false;
        //     }
        //
        //     if(!response.data.data.hasOwnProperty('data'))
        //         return;
        //
        //     setProducts(response.data.data);
        // });

        // Http.post("/sb/getAllPaymentMethods").then(async response => {
        //     if (response.data.status === 'failed') {
        //         showNotification(theme,'danger',rtl,'Get Products',response.data.msg);
        //         return false;
        //     }
        //
        //     debugger
        //     // if(!response.data.data.hasOwnProperty('data'))
        //     //     return;
        //     //
        //     // setProducts(response.data.data);
        // });
        await um.getUserInfo();


    },[]);

    useEffect(async () => {

        if(typeof venueMembers  ==="undefined" || venueMembers.length===0){
            setError("The venue has no users");
            return;
        }
        setError(false);
    },[venueMembers]);



    const getPricesByProductId = () =>{
        let p = document.querySelector('#formCreateSubscription [name=productId]');
        let formData = new FormData();
        formData.append('productId',p.value);
        Http.post("/sb/getAllPricesByProductId",formData).then(async response => {
            if (response.data.status === 'failed') {
                showNotification(theme,'danger',rtl,'Get Prices',response.data.msg);
                return false;
            }
            setPrices(response.data.data);
        });

    };

    const toggle = async () => {
        // setError(false);
        setSuccess(false);
        await setModal(prevState => !prevState);
    };

    const handleSubmit= () =>{
        document.querySelector("#formCreateSubscription button[type=submit]").click();
    };

    const continueSubmission=async (form)=>{
        form.preventDefault();
        setProcessing(true);
        let formData = new FormData(document.getElementById('formCreateSubscription'));

        const cardElement = elements.getElement("card");

        const {error,token} = await stripe.createToken(cardElement);
        // const {error, paymentMethod} = await stripe.createToken(cardElement);

        if(error){
            setError(error.message);
            return;
        }

        let subData = new FormData(document.getElementById("formCreateSubscription"));
        subData.append('stripeToken',token.id);
        subData.append('venueId',venueData.v_id);
        if(useCustomPricing === 'Y'){
            const customPriceInStripeFormat = Math.round(document.querySelector('[name=customPrice]').rawValue.replace('$','') * 100);
            subData.append('customPriceInStripeFormat',customPriceInStripeFormat);
        }

        Http.post("/sb/subscribeVenue",subData).then(async response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setProcessing(false);
                return false;
            }

            setProcessing(false);
            setError(false);
            setSuccess(response.data.msg);
        });

    };

    return (

            <>
                <Button color={"info"} className={"btn-sm btn-block mb-0 mt-2"} onClick={toggle}>Upgrade Plan!</Button>

                <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="xl">
                    <div className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            aria-label="modal__close-btn"
                            type="button"
                            onClick={toggle}
                        />
                        <h4 className="text-modal  modal__title">Upgrade Plan!</h4>
                    </div>

                    <div className="modal__body">

                        {!user.hasPaymentMethod &&
                            <Alert color="warning" className="p-3" style={{display:'block'}}>
                                <Row>
                                    <Col sm={12}>
                                        <h4>You do not have a payment method on file please add one before continuing.</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <AddPaymentMethod/>
                                    </Col>
                                </Row>
                            </Alert>

                        }

                        <Row className="justify-content-center">
                            <CardBasic />
                            <CardPremium />
                            {/*<CardPro />*/}
                        </Row>


                    </div>

                </Modal>
            </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    user : state.user.main,
    users:state.users.users,
    venueData:state.venue.venueData,
    venueMembers:state.venue.members
}))(UpgradePlan);